import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { PreviewComponent } from './preview/preview.component';
import { CardComponent } from './preview/card/card.component';
import { ComponentsModule } from '../components/components.module';
import { ButtonsModule, InputsModule, MDBBootstrapModulesPro, MdbSelectModule } from 'ng-uikit-pro-standard';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home/home.component';
import { ReportsComponent } from './reports/reports.component';
import { GisComponent } from './gis/gis.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { ApplicationsComponent } from './applications/applications.component';
import { AlertsComponent } from './alerts/alerts.component';
import { DatepickerModule, WavesModule } from 'ng-uikit-pro-standard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { MinesComponent } from './mines/mines.component';
import { ProjectsComponent } from './projects/projects.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ServicesUnitComponent } from './services-unit/services-unit.component';
import { JwtModule } from "@auth0/angular-jwt";
import { CompaniesComponent } from './companies/companies.component';
import { UnitsEmtComponent } from './units-emt/units-emt.component';
import { DownloadComponent } from './download/download.component';
import { Front2Component } from './front2/front2.component';

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    PagesComponent,
    PreviewComponent,
    CardComponent,
    HomeComponent,
    ReportsComponent,
    GisComponent,
    MonitoringComponent,
    ApplicationsComponent,
    AlertsComponent,
    MinesComponent,
    ProjectsComponent,
    ServicesUnitComponent,
    CompaniesComponent,
    UnitsEmtComponent,
    DownloadComponent,
    Front2Component,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    ComponentsModule,
    SharedModule,
    DatepickerModule,
    BrowserAnimationsModule,
    WavesModule,
    MdbSelectModule,
    InputsModule,
    ButtonsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    PowerBIEmbedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        // allowedDomains: ["example.com"],
        // disallowedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
    MDBBootstrapModulesPro.forRoot()
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class PagesModule { }


